.overlay {
  padding-top: 88px;
  padding-bottom: 135px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background-color: var(--color-primary);
  z-index: 2001;
}

.close {
  position: absolute;
  top: 8px;
  right: 8px;
  border: 0;
  background-color: transparent;

  svg {
    fill: var(--white);
    width: 1.69rem;
    height: 1.69rem;
  }
}

.links {
  padding: 0;
  margin: 0;
}

.item {
  display: block;
  margin-bottom: 32px;
}

.anchor {
  display: block;
  padding: 0 3.125rem;
  font-family: var(--heading-font);
  font-size: 1.875rem;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 2.5rem;
  color: var(--white);

  &:hover {
    text-decoration: none;
    color: var(--white);
  }
}

.search {
  position: relative;
  margin: 0 3.125rem 32px;
}

.searchIcon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.375rem;
  height: 1.375rem;
  fill: var(--white);
}

.searchInput {
  width: 100%;
  color: var(--white);
  font-family: var(--body-font);
  font-size: 1rem;
  line-height: 2.25rem;
  padding-left: 2.375rem;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid var(--white);
}

.socials {
  display: inline-flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin-left: 3.125rem;
}

.social {
  margin-right: 10px;
}

.socialAnchor {
  svg {
    fill: var(--white);
    width: 1.75rem;
    height: 1.75rem;
  }
}
