.navigation {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 500px;
    background: var(--background);
    background: linear-gradient(180deg, var(--background-offset) 10%, var(--background) 100%);
  }
}

.navbar {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  z-index: 2001;

  @media screen and (min-width: 75rem) {
    display: none;
    overflow: hidden;
  }
}

.live {
  flex: 1 1 auto;
  background-color: var(--color-primary);
  border: 0;
  color: var(--white);
  font-family: var(--heading-font);
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.5rem;
  display: block;
  text-align: center;
  padding: 1rem 0;

  &:hover {
    color: var(--white);
    text-decoration: none;
  }

  &::after {
    content: "";
    display: inline-block;
    width: .875rem;
    height: .875rem;
    border-radius: 50%;
    background-color: var(--red-alt);
    vertical-align: middle;
    margin-left: .5rem;
  }
}

.menu {
  flex: 0 0 auto;
  padding: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.menu {
  background-color: var(--red-alt);
  border: 0;
}

.header {
  padding-top: 32px;
  padding-bottom: 22px;
}

.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 17px;
}

.category {
  margin-right: auto;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: var(--heading-font);
  font-size: 1.25rem;
  line-height: 1.575rem;
  font-weight: 400;
  color: var(--title-color);
}

.logo {
  padding-right: 1.4375rem;

  svg {
    width: 8.5625rem;
    height: 2.3125rem;
    fill: var(--title-color);
  }
}

.themeMobile {
  @media screen and (min-width: 75rem) {
    display: none;
  }
}

.themeDesktop {
  @media screen and (max-width: 75rem) {
    display: none;
  }
}
.linksWrapper {
  height: 43px;
}
.linksContainer {
  display: flex;
  margin-bottom: .375rem;

  &.sticky {
    position: fixed;
    top: 0;
    z-index: 10;
    opacity: 0;
    animation: fade-in .2s ease-in forwards;
    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    .links, .linksDesktop {
        &::before {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        }
    }
  }

  @media screen and (max-width: 75rem) {
    overflow-x: auto;
    padding-right: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 1;
  position: relative;
  z-index: 2;
  &::before {
    content: '';
    background-color: var(--white);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: skew(-12deg);
    z-index: -1;
  }
}

.link,
.liveDesktop,
.menuDesktop {
  display: block;
  font-family: var(--heading-font);
  font-size: 1.25rem;
  line-height: 1.5625rem;
  padding: .375rem 1.2rem;
}

.link {
  color: var(--color-primary);
  white-space: nowrap;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: skew(-12deg);
    z-index: -1;
  }
  &:hover {
    color: var(--white);
    text-decoration: none;
    &::before {
      background-color: var(--red-alt);
    }
  }
}

.linksDesktop {
  display: none;
  position: relative;
  z-index: 1;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: skew(-12deg);
    z-index: -1;
    background-color: var(--color-primary);
  }
  @media screen and (min-width: 75rem) {
    display: flex;
    & > * {
      margin-left: -9px;
    }
  }
}

.liveDesktop,
.liveDesktop:hover {
  color: var(--light-blue-gray);
}

.liveDesktop {
  padding-left: 2rem;
  padding-right: 1.25rem;
  border: 0;

  &::before {
    content: "";
    display: inline-block;
    width: .875rem;
    height: .875rem;
    border-radius: 50%;
    background-color: var(--red-alt);
    margin-left: 0;
    margin-right: .5rem;
  }
}

.menuDesktop {
  border: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-color: transparent;
  svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-top: -.1875rem;
  }

  @media screen and (min-width: 93.75rem) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

}

.lines {
  display: flex;
  list-style: none;
  padding-right: 0;
  margin: 0;
  overflow-x: auto;
  white-space: nowrap;
  min-height: 28px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.lineTopic {
  font-family: var(--body-font);
  font-size: 1rem;
  line-height: 1.6875rem;
  font-weight: 700;
  color: var(--title-color);
  text-transform: uppercase;

  &::after {
    content: ":";
    margin-right: .3125rem;
  }
}

.lineAnchor {
  font-family: var(--body-font);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6875rem;
  color: var(--meta-color);
  margin-right: 1.25rem;

  &:hover {
    color: var(--meta-color);
  }
}

