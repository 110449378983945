.container {

  container-name: teaser;
  container-type: inline-size;
  margin-bottom: 1.5rem;

  &:has(:focus-visible) {
    outline: 2px solid var(--color);
    outline-offset: 2px;
    border-radius: 4px;
  }

}

.anchor {
  position: relative;
  display: block;

  &:hover {
    text-decoration: none;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    .illustration img {
      transform: scale(1.05);
      transform-origin: center;
    }
  }
}

.illustration {
  display: block;
  position: relative;
  overflow: hidden;
  img {
    transition: transform .3s;
  }
}

.play {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(58/16 * 1rem);
  height: calc(58/16 * 1rem);
  z-index: 1;

  @container teaser (width > 35rem) {
    width: calc(90/16 * 1rem);
    height: calc(90/16 * 1rem);
  }

  @container teaser (width > 45rem) {
    width: calc(140/16 * 1rem);
    height: calc(140/16 * 1rem);
  }
}

.livetag {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4% 7%;

  span {
    font-family: var(--heading-font);
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 500;
    color: var(--white);
    background-color: var(--red-alt);
    text-transform: uppercase;
    border-radius: .5rem;
    padding: 2px 10px;
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  position: relative;
  padding: 15px 7% 15px;
  min-height: 7.1rem;
  background: var(--background-teaser);

  @container teaser (width > 35rem) {
    padding-top: 18px;
    padding-bottom: 20px;
  }

  @container teaser (width > 45rem) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.category {
  font-weight: 600;
  color: var(--light-blue-gray);
  background-color: var(--gray-dark);
  padding: 0 10px;
  line-height: 140%;
  display: inline-block;
  border-radius: .5rem;
  margin-bottom: 5px;
}

.title {
  font-size: 1.375rem;
  font-family: var(--heading-font);
  line-height: 1.3;
  font-weight: 600;
  color: var(--white);
  display: -webkit-box;
  min-height: 2.88rem;
  margin: 5px 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @container teaser (width < 25rem) {
    font-size: 1.25rem;
    min-height: 3.5rem;
    -webkit-line-clamp: 3;
  }

  @container teaser (width > 35rem) {
    font-size: 2.25rem;
    line-height: 1.3;
    min-height: 6.3rem;
    margin: 0;
  }

  @container teaser (width > 45rem) {
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 500;
    min-height: 3rem;
  }
}
.pretitle {
  position: absolute;
  transform: translateY(-100%);
  line-height: 1;
  margin: 0;
  width: 90%;
  font-family: var(--heading-font);
  font-size: 1rem;
  @container teaser (width > 35rem) {
    font-weight: 600;
    font-size: 1.25rem;
  }
  span {
    line-height: 1.4;
    white-space: nowrap;
    color: var(--white);
    padding: 0 12px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    position: relative;
    margin-left: -12px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 3px;
      right: 3px;
      bottom: 0;
      transform: skew(-12deg);
      z-index: -1;
      background-color: var(--red);
    }
  }
}
