.stickyPlayerWrapper {
  display: none;

  &[data-active=true] {
    display: block;

    @media screen and (max-width: 45rem) {
      //padding-top: calc(9 / 16 * 100%);

      .stickyPlayer {
        display: block;
        position: fixed;
        padding-top: calc(9 / 16 * 100%);
        width: 100%;
        z-index: 999;
        top: 0;
      }
    }

    @media screen and (min-width: 45rem) {
      .stickyPlayer {
        display: block;
        position: fixed;
        z-index: 999;
        width: 20rem;
        padding-top: calc(9 / 16 * 20rem);
        height: 0;
        right: 1.5rem;
        bottom: 1.5rem;

        :global(#video-wrapper) {
          overflow: hidden;
          border-radius: 8px;
        }
      }
    }
  }
}
