.container {
  margin-bottom: 24px;

  @media screen and (min-width: 61rem) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
  }
}

.illustration {
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2);

  @media screen and (min-width: 61rem) {
    $width: 66%;

    flex: 0 0 auto;
    width: $width;
  }
}

.content {
  @media screen and (min-width: 61rem) {
    flex: 0 0 auto;
    width: 31.5%;
  }
}

.title {
  font-size: 2rem;
  font-family: var(--heading-font);
  line-height: 120%;
  font-weight: 700;
  color: var(--title-color);
  display: -webkit-box;
  margin: 0;
  margin-bottom: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media screen and (min-width: 61rem) {
    font-size: 2.5rem;
    margin-bottom: 48px;
  }
}

.paragraph {
  margin: 0;
  color: var(--meta-color);
  font-size: 1.125rem;
  line-height: 140%;

  @media screen and (max-width: 61rem) {
    margin-bottom: 1rem;
  }

  span {
    color: var(--red-alt);
    border-bottom: 1px solid var(--red-alt);
  }
}

.anchor {
  display: inline-flex;

  @media screen and (max-width: 61rem) {
    align-items: center;
    background: var(--red-alt);
    color: var(--white);
    font-family: var(--heading-font);
    font-size: 1rem;
    line-height: 1.25;
    padding: .5rem 1rem;
    border-radius: 1rem;
    margin-right: 1rem;

    &:hover {
      color: var(--white);
    }
  }

  @media screen and (min-width: 61rem) {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.anchorContent {
  svg {
    width: .75rem;
    height: .75rem;
    margin-right: 5px;
    margin-top: 1px;
    fill: #f1f1f4;
  }

  @media screen and (min-width: 61rem) {
    display: none;
  }
}
