.themeToggle {
  border-radius: 1rem;
  border: 0;
  display: flex;
  align-items: center;
  padding: 0 .3125rem;

  &.isToggle {
    height: 1.1875rem;
    background-color: #929AAE;
    justify-content: space-between;
    width: 2.625rem;
    position: relative;

    $size: 1.25rem;
    &::after {
      content: "";
      display: block;
      width: $size;
      height: $size;
      position: absolute;
      border-radius: 50%;
      transition: all 250ms;
    }

    $offset: .0625rem;
    &[aria-pressed=false]::after {
      left: calc(0px - #{$offset});
      background-color: var(--color-primary);
    }

    &[aria-pressed=true]::after {
      left: calc(100% - #{$size} + #{$offset});
      background-color: var(--white);
    }
  }

  &.isButton {
    border-radius: 0;
    padding: .375rem 1rem;
    transition: background-color 250ms;
    background-color: var(--color-primary);
    fill: var(--white);

    @media screen and (min-width: 93.75rem) {
      padding-left: 1.6875rem;
      padding-right: 1.6875rem;
    }
  }
}

.themeLight,
.themeDark {
  width: .6875rem;
  height: .6875rem;
}

.themeLight {
  @media screen and (min-width: 68.75rem) {
    width: 1rem;
    height: 1rem;
  }
}

.themeDark {
  @media screen and (min-width: 68.75rem) {
    stroke: var(--white);
    stroke-width: 1;
    width: 1rem;
    height: 1rem;
    margin-right: 0;
    fill: transparent;
  }
}
