@import 'src/styles/variables';

.bannerContainer {
  position: relative;
  margin-bottom: $space-m;

  .bannerComponent {
    border-radius: 8px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  }
}
