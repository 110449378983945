// Global Variables

// Colors

@use "sass:math";

$purple-bright: #56147c;
$purple: #3c0a64;
$purple-dark: #2a064c;

$color-primary-bright: #2640bf;
$color-primary: #1a2b80;
$color-primary-dark: #242468;

$white: #ffffff;
$black: #333333;
$red: #e50046;
$green: #37ca21;
$light-blue-gray: #ecedf1;
$light-gray-flat: #b2b2b2;
$lighter-gray-flat: #dedede;
$lightest-gray-flat: #f5f5f5;

$gray-darkest: #1d1f27;
$gray-darker: #2b2e3b;
$gray-dark: #414559;
$gray-mid: #5e616e;
$gray-light: #8f93a3;
$gray-lighter: #c8c9d0;

// Spacing
$space-base: 12px;

$space-xxxs: math.div($space-base, 3);
$space-xxs: math.div($space-base, 2);
$space-xs: math.div($space-base, 1.5);
$space-s: $space-base;
$space-m: $space-base * 2;
$space-l: $space-base * 4;
$space-xl: $space-base * 6;
$space-xxl: $space-base * 8;

// UI Elements
$footerHeight: 400px;
