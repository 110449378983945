.teaserSliderDesktop {
  @media screen and (max-width: 48.75rem) {
    display: none;
  }
}

.teaserSliderMobile {
  @media screen and (min-width: 48.75rem) {
    display: none;
  }
}
