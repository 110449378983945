.superbar {
  background-color: var(--color-primary);
}

.wrapper {
  display: flex;
  max-width: 320px;
  margin: 0 auto;
}
.link {
  padding: .3rem 0;
  opacity: .5;
  &:hover {
    opacity: 1;
  }
    &:nth-child(1) {
      width: 22%;
    }
    &:nth-child(2) {
      width: 18%;
    }
    &:nth-child(3) {
      width: 30%;
    }
    &:nth-child(4) {
      width: 30%;
    }
}
.logo {
  height: 20px;
  max-width: 100%;
  fill: white;
}
