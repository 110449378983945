.container {
  margin-bottom: 36px;

  :global(.slick-list) {
    overflow: hidden;
  }
}

.sliderContainer {
  margin-bottom: 36px;
}

.sliderContainer::after,
.slider::after {
  content: "";
  display: block;
  width: 90%;
  height: .5rem;
  margin: 1px auto;
  background-color: var(--color-primary);
}

.sliderContainer {
  position: relative;
  margin-bottom: 1rem;

  &::after {
    width: 80%;
  }
}

.controlLeft,
.controlRight {
  position: absolute;
  top: calc(50% - .75rem - 2px);
  padding: 0;
  transform: translateY(-50%);
  background-color: transparent;
  border: 0;
  z-index: 1;

  svg {
    width: 2rem;
    height: 2rem;
  }

  &:disabled {
    opacity: .5;
  }
}

.controlLeft {
  left: 1rem;
}

.controlRight {
  right: 1rem;
}

.expand {
  display: block;
  margin: 0 auto;
  background: var(--light-blue-gray);
  border: 0;
  font-size: 1rem;
  line-height: 150%;
  padding: 0 .625rem 1px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  font-family: var(--heading-font);

  svg {
    width: .875rem;
    height: .875rem;
    margin-right: .625rem;
    margin-top: -1px;
  }
}


.teaser {
  margin-bottom: 0;
}

.slidesContainer {
  .teaser {
    margin-bottom: 1rem;
  }
}
