.teaserList {
  container-name: teaserList;
  container-type: inline-size;
  margin-bottom: 24px;
}

.content {
  display: flex;
  justify-content: space-between;
}

.expandedTeaser {
  flex: 0 0 calc(67% - .75rem);
  margin-right: 1.5rem;

  @container teaserList (max-width: 45rem) {
    display: none;
  }
}

.teasers {
  flex: 1 1 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;

  container-name: teasers;
  container-type: inline-size;

  @container teaserList (min-width: 35rem) {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.teaserItem,
.expandableTeaserItem {
  @container teasers (max-width: 35rem) {
    flex: 1 1 100%;
  }

  @container teasers (min-width: 35rem) {
    flex: 0 0 calc(100% * 1/2 - .75rem);
  }

  @container teasers (min-width: 45rem) {
    flex: 0 0 calc(100% * 1/3 - .75rem);
  }

  &:has(:focus-visible) {
    outline: 2px solid var(--color);
    outline-offset: 2px;
    border-radius: 4px;
  }
}

.teaserItem {
  margin-bottom: 20px;
}

.expandableTeaserItem {
  padding-bottom: .75rem;
  margin-bottom: .375rem;
  border-bottom: 1px solid #bad3ea;

  @container teasers (max-width: 45rem) {
    flex: 1 1 100%;
  }

  ul {
    list-style-type: disc;
  }
}

.expandedTeaserItem {
  @container teaserList (min-width: 45rem) {
    display: none;
  }
}

.teaserItemWide {
  @container teasers (min-width: 35rem) {
    flex: 0 0 calc(100% * 1/2 - .75rem);
  }
}
