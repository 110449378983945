.container {

  container-type: inline-size;
}

.iconContainer {
  background: var(--white);
  flex: 0 0 1.45rem;
  margin-left: 1rem;
  padding: .35rem;
  border-radius: 50%;
  transition: transform .2s;
}

.icon {
  display: block;
  width: .75rem;
  height: .75rem;
  //transform: rotate(-45deg);
  //transition: transform 330ms;

  svg {
    fill: var(--gray-mid);
  }
}

.titleContainer {
  flex: 1 1 auto;
}

.title {
  font-size: 1.25rem;
  font-family: var(--heading-font);
  line-height: 1.4;
  font-weight: 600;
  color: var(--title-color);
  display: -webkit-box;
  margin: 0;
  margin-bottom: 8px;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (min-width: 992px) {
    font-size: 1.25rem;
   // line-height: 150%;
  }
}

.meta {
  color: var(--meta-color);
  font-family: var(--body-font);
  font-size: .875rem;
  line-height: 1;
  font-weight: 600;

  @media screen and (min-width: 992px) {
    font-size: .875rem;
  }
}

.anchor {
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
    .iconContainer {
      transform: scale(1.2);
    }
  }

  svg {
    width: .75rem;
    height: .75rem;
    margin-top: 1px;
    fill: #000;
  }
}
