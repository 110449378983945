@import 'src/styles/variables';

.imageComponentWrapper {
  .imageAreaWrapper {
    position: relative;
  }

  img {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  }
}

.imageCredits {
  position: absolute;
  color: rgba(255, 255, 255, 0.75);
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.75);
  left: auto;
  right: 10px;
  bottom: 10px;
  font-size: 10px;
  line-height: 10px;
}

.imageDescription {
  font-size: 14px;
  margin-top: 4px;
  color: $gray-light;
  display: block;
}
