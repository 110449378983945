.container {
  overflow: hidden;
  container-type: inline-size;
}

.anchor {
  position: relative;
  display: flex;
  align-items: flex-start;

  &:hover {
    text-decoration: none;
    .illustration img {
      transform: scale(1.05);
      transform-origin: center;
    }
  }
}

.illustration {
  position: relative;
  overflow: hidden;
  flex: 0 0 112.5px;
  margin-right: 16px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);

  img {
    transition: transform .3s;
  }
}

.play {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(27/16 * 1rem);
  height: calc(27/16 * 1rem);
  z-index: 1;
}

.title {
  font-size: 1rem;
  font-family: var(--heading-font);
  line-height: 140%;
  font-weight: 600;
  color: var(--title-color);
  display: -webkit-box;
  min-height: 2.88rem;
  margin: 0;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (min-width: 1472px) {
    font-size: 1.25rem;
    line-height: 120%;
  }
}

.meta {
  color: var(--meta-color);
  font-family: var(--body-font);
  font-size: .625rem;
  line-height: 120%;
  font-weight: 600;

  @media screen and (min-width: 992px) {
    font-size: .875rem;
  }
}
