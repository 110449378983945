.countdown {
  position: relative;
  background-color: #ffffff;
  background: url('/assets/background-bright.png') no-repeat;
  background-size: contain;
  border-radius: 8px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin-bottom: 24px;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.8) 100%);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 50;
    overflow: hidden;
  }

}

.display {
  width: 100%;
  text-align: center;
}

.cipherBlock {
  display: inline-block;
  position: relative;
  margin-right: 16px;

  &:after {
    content: ':';
    display: inline-block;
    position: absolute;
    top: -1px;
    right: -14px;
    font-size: 1.25rem;
    line-height: 2rem;
    color: var(--gray-lighter);
    margin: 0 4px;

    @media (min-width: 769px) {
      top: -4px;
      right: -16px;
      font-size: 2.5rem;
      line-height: 3.5rem;
    }
  }

  &:last-child {
    margin-right: 0;

    &:after {
      display: none;
    }
  }
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
}

.text {
  text-align: center;
  color: var(--gray-dark) !important;
  margin-top: -48px;
  margin-bottom: 18px;
}

.digit {
  display: inline-block;
  background-color: rgba(229, 0, 70, .75);
  border-radius: 4px;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2rem;
  color: #ffffff;
  text-align: center;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  width: 25px;
  margin: 0 1px;
  padding-bottom: 2px;

  @media (min-width: 769px) {
    border-radius: 8px;
    font-size: 2.5rem;
    line-height: 3.5rem;
    width: 45px;
    margin: 0 2px;
  }
}

.description {
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 0.625rem;
  text-align: center;
  line-height: 1.375rem;
  width: calc(100% - 5px);

  @media (min-width: 769px) {
    font-size: 0.875rem;
  }
}
