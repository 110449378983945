@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  animation: 330ms fadeIn;
}

.container {
  overflow: hidden;
  container-type: inline-size;
}

.trigger {
  background-color: transparent;
  border: 0;
  text-align: left;
  padding: 0;
  display: flex;
  width: 100%;
  align-items: center;
}

.iconContainer {
  background: var(--white);
  flex: 0 0 1.45rem;
  margin-left: 1rem;
  padding: .35rem;
  border-radius: 50%;
}

.icon {
  display: block;
  width: .75rem;
  height: .75rem;
  transform: rotate(-45deg);
  transition: transform 330ms;

  svg {
    fill: var(--gray-mid);
  }
}

.open {
  transform: rotate(0deg);
}

.titleContainer {
  flex: 1 1 auto;
}

.title {
  font-size: 1.25rem;
  font-family: var(--heading-font);
  line-height: 1.4;
  font-weight: 600;
  color: var(--title-color);
  display: -webkit-box;
  margin: 0;
  margin-bottom: 8px;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (min-width: 992px) {
    font-size: 1.25rem;
    line-height: 150%;
  }
}

.meta {
  color: var(--meta-color);
  font-family: var(--body-font);
  font-size: .875rem;
  line-height: 120%;
  font-weight: 600;

  @media screen and (min-width: 992px) {
    font-size: .875rem;
  }
}

.content {
  padding-top: 2rem;
}

.contentHidden {
  display: none;
}

.contentText {
  margin-bottom: 1em;
  color: var(--color);
  font-size: 1.125rem;
  line-height: 140%;
}

.anchor {
  display: inline-flex;
  align-items: center;
  background: var(--red-alt);
  color: var(--white);
  font-family: var(--heading-font);
  font-size: 1rem;
  line-height: 1.25;
  padding: .5rem 1rem;
  margin-right: 1rem;

  &:hover {
    color: var(--white);
  }

  svg {
    width: .75rem;
    height: .75rem;
    margin-right: 5px;
    margin-top: 1px;
    fill: #f1f1f4;
  }
}

.share {
  background-color: transparent;
  border: 0;
  padding: 0;
  color: var(--title-color);
  font-family: var(--heading-font);
  line-height: 125%;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 5px;
    margin-top: -5px;
    fill: var(--title-color);
  }
}
