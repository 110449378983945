@import 'src/styles/variables';

.tabs {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

.tabList {
  background-color: var(--white);
  border-radius: 8px;
  border-color: #aaa;
  padding: 0 12px;
  display: flex;
  margin-bottom: $space-m;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  @media (min-width: 993px) {
    // Disabled for mobile, makes some problems on iOS Safari.
    justify-content: space-around;
  }
}

.tab {
  position: relative;
  list-style: none;
  cursor: pointer;
  padding: $space-m 10px;
  font-weight: 600;
  white-space: nowrap;
  color: var(--color-primary);

  &:focus {
    outline: none;
  }
}

.tabSelected:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  position: absolute;
  background-color: $red;
  bottom: $space-s;
  left: 50%;
  margin-left: -3px;
  border-radius: 3px;
  box-shadow: 0 0 12px #e5004638;
}

.tabPanel {
  display: none;
}

.tabPanelSelected {
  display: block;
}
