@import 'src/styles/variables';

.branding {
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 35rem) {
    position: relative;
    justify-content: right;
  }

  .sponsoredBy {
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 600;
    margin-right: 8px;
    white-space: nowrap;
    color: $gray-dark;
  }
}
